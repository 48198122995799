.selected {
  background: #286fc7;
  border-radius: 12px;
  color: black !important;
}

.top-nav a:hover {
  @extend .selected;
}

@media screen and (max-width: 991px) {
  .top-nav {
    // background: rgba(0, 0, 0, 0.7);
  }
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.bookingTab > a,
.bookingTab > a:hover,
.bookingTab a:focus {
  background-color: #fff !important;
  // border-bottom-color: #042e60 !important;
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-color: #042e60 !important;
  color: #042e60 !important;
}

/* Disabled tab */
.bookingTab > a {
  background-color: #fff !important;
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  color: gray !important;
}

/* Active tab */
@media screen and (max-width: 3000px) {
  .expand-width {
    display: flex;
    justify-content: center;
  }

  .add-horizontal-padding {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (max-width: 1000px) {
  .shrink-width {
    display: flex;
    justify-content: start;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .remove-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

.dashboard-tabs.nav-tabs .nav-link {
  color: #9b9b9b !important;
}

.dashboard-tabs .nav-item.show .nav-link,
.dashboard-tabs .nav-link.active {
  background-color: #fff !important;
  border-top-width: 0px !important;
  border-color: #042e60 !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 2px;
  color: #042e60 !important ;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-right: 30px !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #2b2828;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: #585858;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.open-nav-pannel {
  margin-left: 250px;
}

.close-nav-pannel {
  margin-left: 0;
}

.open-nav {
  width: 250px;
}

.close-nav {
  width: 0;
}

@media screen and (max-width: 750px) {
  .hide-element {
    display: none;
  }
  .enable-wrap {
    flex-wrap: wrap;
  }
}

.notification {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: notification 1s infinite;
}
.notification:hover {
  animation: none;
}

@-webkit-keyframes notification {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(238, 193, 29, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(236, 191, 26, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 193, 13, 0);
  }
}
@keyframes notification {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
